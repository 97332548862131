import React from "react";
import {graphql} from "gatsby";

import Layout from "../../components/layout";
import ImageCaptionSection from "../../components/imageCaptionSection";
import FloatingSection from "../../components/floatingSection";
import EmphasisTextUnderlined from "../../components/emphasisTextUnderline";
import UPIQRSection from "./upiQRSection";
import DirectDonation from "./directDonation";
import AlternateDonationMethods from "./alternateDonationMethods";

const donationLinkConfig = [
  {color: "yellow", imageFirst: false},
  {color: "blue", imageFirst: true},
  {color: "red", imageFirst: false},
  {color: "yellow", imageFirst: true},
  {color: "blue", imageFirst: false},
];

function DonationDetails({details, index, onDonationClicked}) {
  const {description, image, amount} = details;
  const {color, imageFirst} = donationLinkConfig[index];

  const [strokeType, boxColor, boxBorderColor] = {
    yellow: ["strokeSmallYellow", "bg-yellow-500", "border-yellow-500"],
    red: ["strokeSmallRed", "bg-red-500", "border-red-500"],
    blue: ["strokeSmallBlue", "bg-blue-500", "border-blue-500"],
  }[color];
  const boxTranslate = imageFirst ? "right-20" : "left-20";
  const imageLeftMargin = imageFirst ? "ml-auto" : "";

  const descriptionSection = (
    <div className="flex-1 px-20 pt-20 pb-20">
      <FloatingSection>
        <EmphasisTextUnderlined strokeType={strokeType} className="w-36"/>
        <p className="md:text-4xl text-justify pt-10 mb-20">
          {description}
        </p>
        <div className={`flex sm:flex-row flex-col items-center p-5 text-center
              ${boxBorderColor} border-4 rounded-lg shadow-xl bg-gray-100 mb-5`}>
          <button onClick={onDonationClicked}
                  className={`bg-red-500 hover:bg-red-700 px-4 py-2 rounded-xl text-white font-bold m-5`}>
            Donate
          </button>
          <p className="md:text-2xl sm:pr-10 flex-grow">₹{amount}</p>
        </div>
      </FloatingSection>
    </div>
  )

  const imageSection = (
    <div className="relative flex-1">
      <div className={`absolute w-2/3 h-full -top-10 ${boxTranslate} ${boxColor}`}/>
      <FloatingSection>
        <img src={image} alt="Donate" className={`relative w-2/3 ${imageLeftMargin}`}/>
      </FloatingSection>
    </div>
  )
  return (
    <div key={index} className="md:flex flex-row">
      {imageFirst ? <>{imageSection}{descriptionSection}</> : <>{descriptionSection}{imageSection}</>}
    </div>
  );
}

function DonationModes({modes, donationDetails}) {
  const [curIndex, setCurIndex] = React.useState(0);

  const mode = modes?.[curIndex] || null;

  return (
    <div className="md:flex flex-row">
      <div className="md:w-1/3 px-5 md:pl-24">
        <p className="hidden md:block text-xl font-bold mb-5">DONATION METHODS</p>

        <div className="flex flex-row md:flex-col items-start flex-nowrap overflow-x-auto md:overflow-none
        space-x-8 md:space-x-0">
          {modes.map(({titleShort}, index) => (
            <button key={index}
                    onClick={() => setCurIndex(index)}
                    className={`min-w-max md:min-w-0 flex-1 text-left text-xl mb-5 ${
                      curIndex === index ? "text-red-600" : ""}`}>
              {titleShort}
            </button>
          ))}
        </div>
      </div>
      <div className="md:w-2/3 p-10 md:pl-20 md:pr-48">
        {!!mode && (
          <div>
            <p className="text-center text-red-800 text-2xl mb-5">
              {mode.title}
            </p>
            <p className="text-center">{mode.description}</p>
            <div className="py-5">
              {(() => {
                switch (mode.key) {
                  case 'upi':
                    return <UPIQRSection showTitle={false} shrinkWidth={false} upiDefaults={{
                      amount: donationDetails?.amount?.replace(",", "") || "",
                      paymentMadeTowards: donationDetails?.descriptionShort || "",
                    }}/>
                  case 'domestic-bank':
                    return <DirectDonation showForeign={false} showTitle={false}/>;
                  case 'foreign-bank':
                    return <DirectDonation showDomestic={false} showTitle={false}/>;
                  case 'razorpay':
                    return <AlternateDonationMethods showTitle={false} showGiveIndia={false}/>;
                  case 'give-india':
                    return <AlternateDonationMethods showTitle={false} showRazorPay={false}/>;
                  default:
                    return null;
                }
              })()}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const DonatePage = (props) => {
  const {data, pageContext: {slug}} = props;
  const {title, subTitle, imageCaptionBackground} = data.markdownRemark.frontmatter.imageCaptionSection;

  const {donationLinks, donationModes} = data.markdownRemark.frontmatter;
  const [donationIndex, donationDetails] = [...(donationLinks || []).entries()]
    .filter(([i, d]) => d.link && (d.link === slug))[0] || [-1, null];

  const handleDonationClicked = () => {
    document.getElementById("donation-modes")?.scrollIntoView({ behavior: 'smooth' });
  }
  
  return (
    <Layout activeLink="/donate" pageTitle={title} pageDescription={subTitle} {...props}>
      <ImageCaptionSection title={title} subTitle={subTitle} imageAlt={`${title} - ${subTitle}`}
                           imageSrc={imageCaptionBackground}/>
      {!!donationDetails &&
        <DonationDetails details={donationDetails} index={donationIndex} onDonationClicked={handleDonationClicked}/>}
      <div id="donation-modes" className="mb-20">
        <DonationModes modes={donationModes} donationDetails={donationDetails}/>
      </div>
    </Layout>
  );
};

DonatePage.propTypes = {};

export const donatePageIndexQuery = graphql`
  query DonatePageIndexQuery {
    markdownRemark(frontmatter: {templateKey: {eq: "donate"}}) {
        frontmatter {
          imageCaptionSection {
            subTitle
            title
            imageCaptionBackground
          }
          donationLinks {
            description
            descriptionShort
            amount
            link
            image
          }
          donationModes {
            key
            title
            titleShort
            description
          }
        }
      }
  }
`;

export default DonatePage;
